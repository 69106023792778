<template>
  <!-- 导航栏组件 -->
  <div class="nav">
    <!-- 版心1180 -->
    <div class="ban">
      <!-- logo -->
      <div class="logo">
        <img src="@/assets/home/logo.png" alt="" @click="cutcolor('offer')" />
      </div>

      <!-- 导航模块 -->
      <div class="navmod">
        <span
          :class="{ spsu: true, fontblue: colstate == 'offer' }"
          @click="cutcolor('offer')"
          >Quotes</span
        >
        <span
          :class="{ spsu: true, fontblue: colstate == 'Order' }"
          @click="cutcolor('Order')"
          >Orders</span
        >
        <span :class="{ spsu: true, fontblue: colstate == 'Quotes_' }"
          >Customers</span
        >
        <span :class="{ spsu: true, fontblue: colstate == 'Quotes_' }"
          >Help</span
        >
        <!-- <span></span> -->

        <span class="spsu spimg">
          <img src="@/assets/home/tongzhi.png" alt=""
                        style="width: 30px; height: 30px; vertical-align: middle">
          <!-- 未登录状态 -->
          <span
            v-show="navmap == null"
            @click="$router.push({ name: 'login' })"
            style="white-space: nowrap"
            >Sign in</span
          >
          <!-- 已登录状态 @click="perget()"-->
          <el-dropdown v-show="navmap != null">
            <img src="@/assets/home/permap.png" alt=""
                            style="width: 30px; height: 30px; vertical-align: middle">
            <el-dropdown-menu slot="dropdown">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin: 0px 10px;
                  border-bottom: 1px solid #d7e1f0;
                  padding: 10px 0px;
                "
              >
                <img
                  src="@/assets/home/permap.png"
                  alt=""
                  style="margin-right: 10px"
                  v-if="headper==''"
                />
                <img  :src="headper"  alt=""
                  style="margin-right: 10px;border-radius: 50%;width: 40px;height: 40px;"  v-else>
                <span>Hi, {{ nickname }}</span>
              </div>
              <el-dropdown-item @click.native="$router.push({ name: 'per' })"
                >Account management</el-dropdown-item
              >
              <el-dropdown-item @click.native="logoutclick"
                >Sign out</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import URL from "@/url/url";
export default {
  name: "NavNew",
  data() {
    return {
      headper: '',
      // logoimg:require('../../assets/logo.png'),
      colstate: "offer",
      xiastate: "",
      //个人昵称
      nickname: "Aiden",
      //控制注销按键
      logoutbut: true,
      //控制登录按键
      logbut: true,
      //控制导航栏头像是否显示
      navmap: sessionStorage.getItem("token"),
    };
  },

  created() {
    if (sessionStorage.getItem("navColor") == undefined) {
      this.colstate = "offer";
    } else {
      this.colstate = sessionStorage.getItem("navColor");
    }
    if (sessionStorage.getItem("token") != undefined) {
      this.pergetmgs();
      this.logoutbut = true;
      this.logbut = false;
    } else {
      this.logoutbut = false;
      this.logbut = true;
    }
  },
  methods: {
    //获取个人信息
    pergetmgs() {
      axios({
        method: "get",
        headers: { "Content-Type": "multipart/form-data" },
        url: URL.userURL.perdet,
      }).then((res) => {
        console.log(res);
        if (res == undefined) {
          this.open(
            "Your identity token is incorrect. Please log in again and try once more.",
            "Failed"
          );
          sessionStorage.removeItem("token");
        }
        if (res.code == 1) {
          console.log(res, "res");
          this.headper = res.data.userinfo.avatar;
          this.nickname =
            res.data.userinfo.firstname + " " + res.data.userinfo.lastname;
        }
      });
    },

    //选中功能颜色和跳转页面
    cutcolor(item) {
      // sessionStorage.setItem('navColor',item)
      sessionStorage.setItem("navColor", item);
      console.log(sessionStorage.getItem("token"), "token值");
      if (sessionStorage.getItem("token") == undefined) {
        sessionStorage.removeItem("navColor");
        // console.log('走到这么吗');
        this.$router.push(
          {
            // 当没有登录就登录
            name: "login",
          },
          () => {}
        );
        return;
      }
      this.colstate = item;
      console.log("this.colstate", this.colstate);
      if (item == "offer") {
        sessionStorage.setItem('asideNav','Quotes')
        this.$router.push(
          {
            name: "offer",
          },
          () => {}
        );
      }

      if (item == "Order") {
        this.$router.push(
          {
            name: "order",
          },
          () => {}
        );
      }
    },

    xiahua(item) {
      this.xiastate = item;
    },
    //点击进入个人信息页面//为什么用这总方式进不去啊？？
    perget() {
      // debugger
      this.$router.push(
        {
          name: "per",
        },
        () => {}
      );
    },

    // 注销
    logoutclick() {
      axios({
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url: URL.userURL.logout,
      }).then((res) => {
        if (res.code == 1) {
          // this.open(res.msg,'Infomation')
          sessionStorage.removeItem("account");
          sessionStorage.removeItem("password");
          sessionStorage.removeItem("token");
          this.navmap = sessionStorage.getItem("token");
        }
      });
    },
    //弹窗
    open(item, item2) {
      this.$alert(item, item2, {
        confirmButtonText: "Confirm",
      });
    },
  },
  watch: {
    navmap(newVal) {
      if (newVal == null) {
        this.$router.push(
          {
            name: "login",
          },
          () => {}
        );
        sessionStorage.setItem("navColor", "login");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./nav.scss";
</style>
