import {agency} from '@/agency/agency.js'
const url={
     // 用户接口
     userURL:{
        //登录接口
        login:agency+'/oil/comuser/user/login',
        //图形验证码
        tuke:agency+'/index.php?s=/captcha&t=',
        //个人信息
        perdet:agency+'/oil/comuser/user/index',
        //修改密码
        changepwd:agency+'/oil/comuser/user/changepwd',
        //修改个人信息
        changemgs:agency+'/oil/comuser/user/profile',
        //修改个人头像
        avatarURL:agency+'/oil/comuser/user/change_avatar',
        //注册账号
        register:agency+'/oil/comuser/user/register',
        //发送邮箱验证码
        send:agency+'/oil/comuser/ems/send',
        //重置密码（忘记密码）
        resetpwd:agency+'/oil/comuser/user/resetpwd',
        //变更邮箱
        changeemail:agency+'/oil/comuser/user/changeemail',
        //安全退出
        logout:agency+'/oil/comuser/user/logout',
        //上传文件
        upload:agency+'/oil/comuser/common/upload',
        //公司认证详情
        company:agency+'/oil/comuser/company/companyinfo',
        //申请公司认证
        companyinfo:agency+'/oil/comuser/company/change_companyinfo',
        //修改公司认证信息
        companyedit:agency+'/oil/comuser/company/companyedit'
    },
    //公共接口
    commURL:{
        //港口接口
        portURL:agency+'/oil/home/oilport/list',
        //油品接口
        oilURL:agency+'/oil/home/oil/list',
        //国家接口
        countryURL:agency+'/oil/home/country/list',
    },
    //trader端
    //报价服务
    offerURL:{
        //公共询价列表
        yourderURL:agency+'/oil/trader/enquiryorder/list',
        //新增报价
        manyaddURL:agency+'/oil/trader/quotationproduct/manyadd',
        //编辑报价
        manyeditURL:agency+'/oil/trader/quotationproduct/manyedit',
        //报价列表
        quoURL:agency+'/oil/trader/quotationproduct/list',
        //删除报价信息
        delmanyURL:agency+'/oil/trader/quotationproduct/manydel',
        // 公共询价详情
        publickinfoURL:agency+"/oil/trader/enquiryorder/info",
        //报价详情
        priceinfoURL:agency+'/oil/trader/quotationproduct/manyinfo'
    },
    //订单列表
    orderURL:{
        //订单列表
        orderlistURL:agency+'/oil/trader/order/list',
        //订单详情
        orderinfoURL:agency+'/oil/trader/order/info',
        //确认收货
        confirmURL:agency+'/oil/trader/order/confirm',
    },

    // 历史订单
    historyURL:{
         //新增历史订单
         hisaddURL:agency+'/oil/trader/historyorder/add',
         //历史订单列表
         hislistURL:agency+'/oil/trader/historyorder/list',
         //删除历史订单
         hisdelURL:agency+'/oil/trader/historyorder/del',
    },

    //评论管理
    commentURL:{
        //添加评论
        commentaddURL:agency+'/oil/trader/comment/add',
        //
        commentsonURL:agency+'/oil/trader/comment/info_order',
    }
}
export default url