import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //控制BDN上传弹窗的显示隐藏
    show_hidden:false,
    //上传BDN订单的iD
    order_BDN_id:'',
    //上传BDN订单里面油品的ID
    order_BDN_oil_id:'',
  },
  getters: {
  },
  mutations: {
    //点击BDN时调用
    show_hidden_click(state,itme_order){
      state.show_hidden=!state.show_hidden
      state.order_BDN_id=itme_order.id
      itme_order.orderoil.forEach(item => {
        state.order_BDN_oil_id=item.id
      });
    },
    //点击弹窗关闭
    show_onoroff(state){
      state.show_hidden=!state.show_hidden
    }
  },
  actions: {
  },
  modules: {
  }
})
