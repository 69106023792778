<template>
  <div class="login">
    <!-- 导航栏 -->
    <div>
      <Nav></Nav>
    </div>
    <div class="mian">
      <div class="ban">
        <div class="head">
          <span>Sign in</span>
        </div>
        <!-- 登录表单验证 -->
        <el-form ref="loginList" :model="loginList" :rules="rules">
          <el-form-item prop="account" class="formsu">
            <el-input
              v-model="loginList.account"
              name="account"
              placeholder="E-mail"
              @focus="handlehead(1)"
            >
              <template slot="prefix">
                <span
                  class="headspan"
                  v-show="inputhead == 1 || loginList.account != ''"
                  >E-mail</span
                >
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="password" class="formsu">
            <el-input
              :type="passwordShow ? 'text' : 'password'"
              v-model="loginList.password"
              name="password"
              @focus="handlehead(2)"
              placeholder="Password"
              
            >
              <template slot="prefix" >
                <span
                  class="headspan"
                  v-show="inputhead == 2 || loginList.password != ''"
                  >Password</span
                >
              </template>
              <template #suffix>
                <i @click="passwordShow = !passwordShow">
                    <img :src="passwordShow? require('../../assets/eye/eyeoff.png'): require('../../assets/eye/eyeon.png')"/>
                    </i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item prop="captcha" class="formsu">
            <div class="capcss">
              <el-input
                type="captcha"
                v-model="loginList.captcha"
                name="captcha"
                @focus="handlehead(3)"
                placeholder="Captcha"
              >
                <template slot="prefix">
                  <span
                    class="headspan"
                    v-show="inputhead == 3 || loginList.captcha != ''"
                    >Captcha</span
                  >
                </template>
              </el-input>

              <div class="tukuimg" @click="gettuku">
                <img :src="tukuUrl" alt="" />
              </div>
            </div>
          </el-form-item>

          <el-form-item class="formsu">
            <el-button type="primary" @click="loginclick">Sign in</el-button>
          </el-form-item>
        </el-form>

        <div class="signUp">
          <span
            >Dont have an account yet ?
            <span class="suup" @click="registerclick">Sign up</span></span
          >
        </div>

        <div class="Forgotpwd">
          <span class="pwdcss" @click="resetpwdClick">Forgot password?</span>
        </div>

        <!-- <div class="hint">
          <span>A good password may include: 8-20 characters,</span>
          <span
            >symbols allowed, numbers, uppercase letters, lowercase
            letters.</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/views/comm";
import axios from "@/axios";
import URL from "@/url/url.js";
export default {
  name: "ShipLogin",
  data() {
    return {
      //控制密码输入框的眼睛
      passwordShow:false,
      //输入框获取焦点后显示状态变量
      inputhead: "",
      loginList: {
        account: "",
        password: "",
        // 图形验证码
        captcha: "",
      },
      tukuUrl: "",
      rules: {
        account: [
          {
            required: true,
            message: "Please enter the correct email address",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "Please enter the correct email address",
            trigger: "blur",
          },
          {
            pattern:  /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
            message: "Please enter the correct email address",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "The length is 3 to 20 characters",
            trigger: "blur",
          },
        ],
        captcha: [
          {
            required: true,
            message: "Please enter the captcha",
            trigger: "blur",
          },
          {
            min: 4,
            max: 4,
            message: "The verification code can only be 4 digits",
            trigger: "blur",
          },
        ],
      },
    };
  },

  created: function () {
    // this.tuku()
    this.gettuku();
  },
  components: {
    Nav,
  },
  methods: {
    //输入框获取焦点后显示状态
    handlehead(item) {
      this.inputhead = item;
    },
    //图形验证码
    gettuku() {
      //打包上传前需更新
      this.tukuUrl = URL.userURL.tuke + Math.random();
      if (sessionStorage.getItem("account") !== null) {
        this.loginList.account = sessionStorage.getItem("account");
        this.loginList.password = sessionStorage.getItem("password");
      }
    },
    //登录
    loginclick() {
      this.$refs.loginList.validate((valid)=>{
        if(valid){

          axios({
            method: "POST",
            url: URL.userURL.login,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: this.loginList,
          }).then((res) => {
            if (res.code == 0) {
              this.open(res.msg);
            }
            if (res.code == 1) {
              if(res.data.userinfo.user_type=='owner'){
                this.open('This account is the customer account. Please log in from the customer side')
                return
              }
              const logindata = res.data.userinfo;
              sessionStorage.setItem("token", logindata.token);
              sessionStorage.setItem("navColor", "home");

              this.$router.push(
                {
                  name: "offer",
                },
                () => {}
              );
            } else {
              this.gettuku();
              this.open(res.msg);
            }
          }).catch(()=>{
            this.open('Network error or other')
          })
        }else{
          return
        }
      })
    },
    //弹窗
    open(ts) {
      this.$alert(ts, "Hint", {
        confirmButtonText: "Confirm",
      });
    },
    //注册页跳转
    registerclick() {
      this.$router.push(
        {
          name: "register",
        },
        () => {}
      );
    },
    //忘记密码页跳转
    resetpwdClick() {
      this.$router.push(
        {
          name: "resetpwd",
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./shiplogin.scss";
</style>