import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login'
// import Pricefrom from '../views/offer/pricefrom'

Vue.use(VueRouter)
const routes = [

  // 登录页路由
  {
    path: '/',
    name: 'login',
    component: Login
  },

  //注册页路由
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register')
  },
  //重置密码路由
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: () => import('../views/login/resetpwd')
  },

  //抢单大厅
  {
    path: '/offer',
    name: 'offer',
    component: () => import('../views/offer'),
    children:[
      {
        path:'haveposts',
        name:"haveposts",
        component:()=>import('../views/offer/haveposts')
      },
      {
        path:'hisquo',
        name:"hisquo",
        component:()=>import('../views/offer/hisquo')
      }
    ]
   
  },
 //报价页面
 {
  path: '/priceoffer',
  name: 'priceoffer',
  component: () => import('../views/offer/pricefrom')
},

  //修改报价页面
  {

    path: '/alterprice',
    name: 'alterprice',
    component: () => import('../views/offer/hisquo/alterprice')

  },

 //个人信息路由
 {
  path: '/per',
  name: 'per',
  component: () => import('../views/per'),
  children: [
    {
      path: 'unverified',
      component: () => import('@/views/per/company_cer/unverified')
    },
    {
      path: 'authenticated',
      component: () => import('@/views/per/company_cer/authenticated/authenticated.vue')
    },
    {
      path: 'amend',
      component: () => import('@/views/per/company_cer/amend/Amend.vue')
    },
    {
      path: 'basic',
      component: () => import('@/views/per/basic')
    },
  ]
},
  //订单管理路由
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order'),
    children: [

      //有数据页面
      {
        path: 'haveorder',
        name: 'haveorder',
        component: () => import('../views/order/haveorderlist')
      },

    ]
  },
      //订单详情和确认
      {
        path: '/details',
        name: 'details',
        component: () => import('../views/order/details')
      },
  //订单评价路由
  {
    path: '/appraise',
    name: 'appraise',
    component: () => import('../views/order/orderdatails/appraise')
  },
  // 线下订单上传列表 路由
  {
    path: '/upload',
    name: 'upload',
    component: () => import('../views/order/upload')
  },
  // 线下订单上传表单页面 路由
  {
    path: '/uploadform',
    name: 'uploadform',
    component: () => import('../views/order/upload/upform')
  },
]



const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  // 判断是否进入 /user 路径
  if (to.path == '/order') {
    // 将路径重定向为 /user/setting
    next({ path: '/order/haveorder' })
  } else {
    // 不需要进入 /user 路径
    next()
  }
  if(to.path=='/offer'){
    next({ path: '/offer/haveposts' })
  }
  if (to.path == '/per'){
    next({path:'/per/basic'})
  }else{
    next()
  }
})
//  // // 路由守卫 用白名单的方式
//  const bailist = ['/','/priceoffer']
//  router .beforeEach((to, from, next) => {
//          console.log(to,from, next);
//          if (bailist.indexOf(to.fullpath) > -1){
//          next(true)
//          } else {
//          if (sessionStorage.getItem( 'token') !== null) {
//            next(true)}
//                else {
//                  next(false)
//                }

//          }})

export default router
